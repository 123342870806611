<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" @click="addUser" v-right-code="'Sysuser:Add'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="enableUsers" v-right-code="'Sysuser:Enable'">启用</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="logoutUsers" v-right-code="'Sysuser:Enable'">停用</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="userListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='UserName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else-if="col.FieldName==='EnableStr'" width="100%"><el-tag :type="scope.row.IsEnable ? 'success' : 'danger'">{{ scope.row[col.FieldName] }}</el-tag></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" :min-width="130" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="showReset(scope.row)" v-right-code="'Manage_Right_System_User_ResetPwd'" size="small" type="text">重置密码</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
        <!--重置密码-->
        <DialogEx :options="tUserOptions" title="重置密码" @onOk="resetPwd_onOk" style="width:70%;margin-left:15%;">
            <ResetPwd ref="resetUserPwd" :UserId="UserId" :UserName="UserName"></ResetPwd>
            <template slot="ButtonGroupLeft">
                <el-button type="primary" @click="resetPwdForm">清空</el-button>
            </template>
        </DialogEx>
        <DialogEx :options="tDistrictOptions" title="添加数据权限" @onOk="onDistrictOk">
            <District ref="dist" :config="districtConfig" @onSelectedRow="onDistrictSelectedRow" style="height:450px;"></District>
        </DialogEx>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadUserPageList", () => this.reloadPageList());
            this.Event.$on("onAddUser", () => this.addUser());
            this.initialize();
        },
        data() {
            return {
                tUserOptions: {
                    visible: false
                },
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                tDistrictOptions: {
                    visible: false,
                    size: 'small'
                },
                tUserOptions: {
                    visible: false
                },
                districtConfig: {
                    isDetailDisplay: true,
                    isSelectionDisplay: true,
                },
                UserId: '',
                UserName:''
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/sysuser/getusers", "post", param, data => {

                    _this.dataSource = data;

                    // _this.$refs.userListTable.doLayout();

                    // alert(0)
                    // var s = _this.dataSource.ColDefs.BodyFieldParams;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/sysuser/search", "get", { id: row.UserId }, (data) => {
                    this.Event.$emit("clearEditUserForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            addUser() {
                this.$ajax.send("omsapi/sysuser/getemptyuser", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            enableUsers() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择启用的用户.`, "error");
                    return false;
                }

                this.$ajax.send("omsapi/sysuser/enable", "post", this.multipleSelection, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("启用成功.", "success");
                });
            },
            logoutUsers() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择停用用户.`, "error");
                    return false;
                }

                var isValid = true;
                this.multipleSelection.forEach(item => item.IsSuperAdmin && (isValid = false));
                if (!isValid) {
                    this.Utils.messageBox(`系统用户不能被停用.`, "error");
                    return false;
                }

                this.$ajax.send("omsapi/sysuser/remove", "post", this.multipleSelection, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("停用成功.", "success");
                });
            },
            addDataPerm() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择用户.`, "error");
                    return false;
                }
                this.tDistrictOptions.visible = true;
                this.Event.$emit("refreshSelectDistrictForm");
            },
            onDistrictSelectedRow: function (data) {
                this.currentDistrictSelectedRows = data;
            },
            onDistrictOk() {
                var _this = this;
                var rows = _this.currentDistrictSelectedRows;
                console.log(rows);
                if (!rows || rows.length == 0) return;
                var _this = this;
                var userIDs = _this.Utils.selectionsToArr(_this.multipleSelection, "UserId");
                var sysUserDistrictDTO = { DistrictList: rows, UserIDs: userIDs };
                _this.$ajax.send("omsapi/sysuser/editsysuserperm", "post", sysUserDistrictDTO, (result) => {
                    _this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            showReset: function (row) {
                this.tUserOptions.visible = true;
                this.UserId = row.UserId;
                this.UserName = row.UserName;
                this.$refs.resetUserPwd.resetForm();
            },
            resetPwd_onOk(options) {
                this.$refs.resetUserPwd.resetPwd(options);
            },
            resetPwdForm() {
                this.$refs.resetUserPwd.resetForm();
            }
        },
        components: {
            "ResetPwd": resolve => { require(['./resetpwd.vue'], resolve) },
            "District": resolve => { require(['@/components/business/selector/districtselector.vue'], resolve) },
        }
    }
</script>
<style>

</style>